import React from "react";

function BlogHero({ children, image }) {
  return (
    <div className="mb-8">
      <img src={`/images/${image}.svg`} alt="" className="bloghero-image" />
      <h1 className="text-center hero-content text-3xl sm:text-4xl font-bold leading-tight text-indigo-800 text-shadow">
        {children}
      </h1>
    </div>
  );
}

export default BlogHero;
