import React from "react";
import Layout from "../components/layout/Layout";
import BlogHero from "../components/layout/BlogHero";
import { graphql, Link } from "gatsby";
import SEO from "../components/seo/SEO";
import formatDate from "../utils/formatDate";

function BlogPost({
  data: {
    markdownRemark: {
      frontmatter: { author, path, date, title, image },
      html,
      excerpt
    }
  }
}) {
  const formattedDate = formatDate(date);

  return (
    <>
      <SEO
        title={`${title} / Blogi`}
        description={excerpt}
        image={`/images/${image}.svg`}
        path={path}
      />
      <Layout>
        <BlogHero image={image}>{title}</BlogHero>

        <div className="text-center">
          <span>{formattedDate}</span> • <span>{author}</span>
        </div>

        <div className="blog-content xl:w-3/5 lg:w-3/4 text-lg">
          <div
            className="flex-3 mt-8 md:mt-12 markdown-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>

        <div className="text-center mt-8">
          <a href="#top">Takaisin ylös</a>
        </div>
      </Layout>
    </>
  );
}

export default BlogPost;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        author
        title
        path
        image
        date
        relatedCourses
      }
    }
  }
`;
